export const ThemeData = {
  components: {
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(20, 60, 125, 0.1)',
          color: '#3A3541',
        },
      },
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        headerCell: {
          backgroundColor: 'rgba(20, 60, 125, 0.1)',
          color: '#3A3541',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#1976d2',
        },
        button: {
          color: '#1976d2',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Manrope, Helvetica, Arial, sans-serif',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          fontFamily: 'Manrope, Helvetica, Arial, sans-serif',
          backgroundColor: '#F4F4F4',
        },
      },
    },
  },
  palette: {
    text: {
      primary: '#424447',
      secondary: '#7D7A7A',
    },
    success: {
      main: '#61A534',
      light: 'rgba(97, 165, 52, 0.10)',
      contrastText: 'rgba(255,255,255,1)',
    },
    error: {
      main: '#d32f2f',
      light: 'rgb(239 83 80 / 21%)',
    },
    background: {
      default: '#F4F4F4',
    },
    primary: {
      main: '#143c7d',
      light: 'rgba(20, 60, 125, 0.1)',
      dark: '#0E2A57',
      contrastText: 'rgba(255,255,255,1)',
    },
    secondary: {
      main: '#61A534',
      light: 'rgba(97, 165, 52, 0.2)',
      contrastText: 'rgba(255,255,255,1)',
    },
    brandSecondary1: {
      main: '#347D14',
      light: '#d4e3ce',
      contrastText: 'rgba(255,255,255,1)',
    },
    brandSecondary2: {
      main: '#43bbc8',
      light: 'rgba(67, 187, 200, 0.3)',
      contrastText: 'rgba(255,255,255,1)',
    },
    brandSecondary3: {
      main: '#D30031',
      light: '#f5cad3',
      contrastText: 'rgba(255,255,255,1)',
    },
    brandSecondary4: {
      main: '#F68932',
      light: '#fbe5d4',
      contrastText: 'rgba(255,255,255,1)',
    },
    brandLightGrey: {
      light: '#DDDDDD',
      main: '#7D7A7A',
      contrastText: 'rgba(255,255,255,1)',
    },
    brandDarkGrey: {
      main: '#424447',
      light: '#DDDDDD',
      contrastText: 'rgba(255,255,255,1)',
    },
    brandColor: {
      main: '#43bbc8',
      light: 'rgba(20, 60, 125, 0.1)',
      contrastText: 'rgba(255,255,255,1)',
      menuGradient: '#436397',
    },
  },
};
